import React, { useContext, useState } from "react";
import copyText from "../../utils/copyText";

//component
import CharCount from "./CharCount";
import { TfiReload } from "react-icons/tfi";
import { WebsiteContext } from "../../context/WebsiteContext";
import { BiCopy } from "react-icons/bi";
const Table = (props) => {
  const [loadingHeading, setLoadingHeading] = useState([]);
  const [loadingDescription, setLoadingDescription] = useState([]);
  const ctx = useContext(WebsiteContext);
  const { scrapedInfo, formInfo, tables, setTables, setError } = ctx;

  const regenerate = async (text, index, section) => {

    try {
      props.setIsRegenerating(true); //block the rest of the buttons when the content is regenerating
      if (section === "headline") {
        setLoadingHeading((prev) => {
          const copy = [...prev];
          copy[index] = true;
          return copy;
        });
      } else {
        setLoadingDescription((prev) => {
          const copy = [...prev];
          copy[index] = true;
          return copy;
        });
      }

      const response = await fetch("https://phpstack-204910-3504426.cloudwaysapps.com:3003/regenerateClaude", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          info: formInfo,
          data: scrapedInfo,
          text,
          tables,
          section,
        }),
      });

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      const result = await response.json();
      if (section === "heading") {
        setLoadingHeading((prev) => {
          const copy = [...prev];
          copy[index] = false;
          return copy;
        });
      } else {
        setLoadingDescription((prev) => {
          const copy = [...prev];
          copy[index] = false;
          return copy;
        });
      }
      const formattedResult = result.slice(result.indexOf("{"));
      const jsonOutput = JSON.parse(formattedResult);
      if (section === "headline") {
        setLoadingHeading((prev) => {
          const copy = [...prev];
          copy[index] = false;
          return copy;
        });
      } else {
        setLoadingDescription((prev) => {
          const copy = [...prev];
          copy[index] = false;
          return copy;
        });
      }
      props.setIsRegenerating(false); //block the rest of the buttons when the content is regenerating
      setTables(jsonOutput);
    } catch (e) {
      setError(e.message);
      console.log(e);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <h1
        className={`font-semibold text-2xl max-w-[700px] text-slate-50`}
      >
        {props.heading}
      </h1>

      {props.data.map((row, index) => (
        <div
          key={index}
          className="
            p-4
            w-[90vw] max-w-[700px]
            bg-slate-50
            rounded-xl
            shadow-md
           
            "
        >
          <div
            className="
          flex
          justify-between
          items-center
          "
          >
            <h2 className="text-gray-400">Headline</h2>
            <CharCount text={row.headline} maxNum={30} />
          </div>
          <div
            className="
          flex
          items-center
          jutify-center
          gap-2
          mb-4
          "
          >
            <div
              className="
            ml-4
            flex
            flex-col
            items-center
            justify-center
            gap-2
            text-gray-400
            "
            >
              <BiCopy
                className="cursor-pointer !text-[1.3rem]"
                onClick={() => {
                  copyText(row.headline);
                }}
              />
              <TfiReload
                className={`${loadingHeading[index] === true && "rotate"} ${
                  props.isRegenerating ? "cursor-not-allowed" : "cursor-pointer"
                } !text-[1.3rem] `}
                onClick={() => {
                  if (!props.isRegenerating) {
                    regenerate(row.headline, index, "headline");
                  }
                }}
              />
            </div>
            <p>{row.headline}</p>
          </div>
          <div
            className="
          flex
          justify-between
          items-center
          "
          >
            <h2 className="text-gray-400">Description</h2>
            <CharCount text={row.description} maxNum={90} />
          </div>
          <div
            className="
          flex
          items-center
          jutify-center
          gap-2
          "
          >
            <div
              className="
            ml-4
            flex
            flex-col
            items-center
            justify-center
            gap-2
            text-gray-400
            "
            >
              <BiCopy
                className="  cursor-pointer !text-[1.3rem]"
                onClick={() => {
                  copyText(row.description);
                }}
              />
              <TfiReload
                className={`${
                  loadingDescription[index] === true && "rotate"
                }  !text-[1.3rem]  ${
                  props.isRegenerating ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => {
                  if (!props.isRegenerating) {
                    regenerate(row.description, index, "description");
                  }
                }}
              />
            </div>

            <p>{row.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Table;
