import React from "react";

//Hooks
const UrlForm = (props) => {
  const { related_1, related_2, related_3, homepage, service, about } = props;

  return (
    <div className="flex flex-col justify-center items-center  w-[100%] ">
      <label htmlFor="homepage" className="text-left w-full">
        Website homepage URL
      </label>
      <input
        placeholder="http://"
        id="homepage"
        type="text"
        className={` w-full mb-5  px-2 `}
        onBlur={() => homepage.inputBlurHandler()}
        onChange={(e) => homepage.onChange(e.target.value)}
        value={homepage.value}
      />

      <label htmlFor="service" className="text-left w-full">
        Website service page URL
      </label>
      <input
        placeholder="http://"
        id="service"
        type="text"
        className={`  w-full mb-5  px-2`}
        onBlur={() => service.inputBlurHandler()}
        onChange={(e) => service.onChange(e.target.value)}
        value={service.value}
      />

      <label htmlFor="about" className="text-left w-full">
        Website about page URL
      </label>
      <input
        placeholder="http://"
        id="about"
        type="text"
        className={`  w-full mb-5  px-2`}
        onBlur={() => about.inputBlurHandler()}
        onChange={(e) => about.onChange(e.target.value)}
        value={about.value}
      />

      <div className="flex flex-col items-center gap-2  mt-10 w-full">
        <p
          className="
        text-[14px]
        self-start
        "
        >
          Related web pages
        </p>
        <input
          placeholder="http://"
          id="related_1"
          type="text"
          className={`border w-full bg-[#fff] px-2`}
          onBlur={() => related_1.inputBlurHandler()}
          onChange={(e) => related_1.onChange(e.target.value)}
          value={related_1.value}
        />
        <input
          placeholder="http://"
          id="related_2"
          type="text"
          className={`border w-full bg-[#fff]  px-2`}
          onBlur={() => related_2.inputBlurHandler()}
          onChange={(e) => related_2.onChange(e.target.value)}
          value={related_2.value}
        />
        <input
          placeholder="http://"
          id="related_3"
          type="text"
          className={`border w-full bg-[#fff]  px-2`}
          onBlur={() => related_3.inputBlurHandler()}
          onChange={(e) => related_3.onChange(e.target.value)}
          value={related_3.value}
        />
        <p
          className="
    
      text-slate-400 
      text-[0.75rem] 
      text-left"
        >
          Add links to pages on your site that are related to the
          service/product, showcase your expertise and authority and highlight
          customer testimonials or reviews <br />
          Please input only one URL per input
        </p>
      </div>
    </div>
  );
};

export default UrlForm;
