import React,{useContext} from "react";
//React components
import { BiError } from "react-icons/bi";
import { WebsiteContext } from "../../context/WebsiteContext";
const Modal = () => {
  const ctx = useContext(WebsiteContext)
  if (!ctx.error) {
    return null;
  }
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-[100%] h-[100%] bg-black/[0.5]  z-10">
      <div className="bg-slate-50 w-[90%] max-w-[40rem] p-10 flex flex-col justify-center items-center gap-5 rounded-3xl">
        <div className="flex flex-col justify-center items-center">
          <BiError size={100} className="text-red-600"/>
          <h1 className="font-semibold text-2xl">Error!</h1>
        </div>
        <p>{ctx.error}</p>
        <button
          className="bg-[#5483ED] hover:bg-[#486dc2] px-4 py-1 cursor-pointer text-slate-50 rounded-md shadow-lg"
          onClick={() => {
            ctx.setError(null);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
