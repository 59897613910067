import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Helper function
const convertData = (data) => {
  const result = [];

  Object.keys(data).forEach((adType) => {
    if (adType !== "funny") {
      data[adType].forEach((ad, index) => {
        result.push({
          adType: adType,
          headline: data[adType][index].headline,
          description: data[adType][index].description,
        });
      });
    } else if (adType === "funny") {
      data[adType].map((ad) => {
        result.push({ adType: adType, headline: ad });
        return null;
      });
    }
  });
  //console.log(result);
  return result; // Don't forget to return the result
};

export const exportToExcel = (data, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(convertData(data));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBuffer = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(dataBuffer, fileName + fileExtension);
};

export const exportToCSV = (data, fileName) => {
  const fileType = "text/csv;charset=utf-8";
  const fileExtension = ".csv";

  //const header = Object.keys(data[0]).join(",") + "\r\n";
  //const csvContent = data.map(row => Object.values(row).join(',')).join('\r\n');
  const header = ["AdType", "Headline", "Description", "\r\n"];
  let csvContent = "";

  Object.keys(data).forEach((key) => {
    if (key !== "funny") {
      data[key].forEach((ad) => {
        csvContent =
          csvContent +
          [key, ad.headline, `"${ad.description}"`] + "\r\n";
      });
    }
     else if (key === "funny") {
      data[key].forEach(ad =>{
        csvContent = csvContent + [key , `"${ad}"`] + '\r\n'
      })
    }
  });
  //console.log(csvContent);
  const csvData = header + csvContent;
  const dataBuffer = new Blob([csvData], { type: fileType });
  FileSaver.saveAs(dataBuffer, fileName + fileExtension);
};
