import React, { useContext, useState } from "react";
import { Link, Element } from "react-scroll";
//Components
import Table from "./Table";
import { BiCopy } from "react-icons/bi";
//Utils
import copyText from "../../utils/copyText";
import DropDown from "../UI/DropDown";
import { WebsiteContext } from "../../context/WebsiteContext";

const Tables = (props) => {
  const [isRegenerating, setIsRegenerating] = useState();

  const ctx = useContext(WebsiteContext);
  const {
    isSectionOpen,
    setIsSectionOpen,
    setIsDropdownOpen,
    tables,
    formInfo,
  } = ctx;

  const toggleMenu = () => {
    setIsDropdownOpen(false);
    setIsSectionOpen(!isSectionOpen);
  };

  return (
    <div>
      {tables && (
        <div className="flex flex-col gap-2 xs:gap-20 pt-10 xs:py-20 px-0 z-20 relative">
          <div
            className="
          flex
          flex-wrap
          flex-col xs:flex-row
          items-center
          justify-center
          gap-2
          "
          >
            <button
              className={`bg-slate-50 px-4 p-2 rounded-md shadow-md w-[90vw] xs:w-auto ${
                isRegenerating && "cursor-not-allowed "
              }`}
              onClick={() => {
                props.fetchData(ctx.formInfo, ctx.scrapedInfo);
              }}
              disabled={isRegenerating}
            >
              Regenerate
            </button>
            <DropDown data={tables} />

            <div className="relative inline-block ">
              <button
                onClick={toggleMenu}
                className="bg-slate-50 px-4 p-2 rounded-md shadow-md w-[90vw] xs:w-auto"
              >
                Section
              </button>
              {isSectionOpen && (
                <div className="absolute   mt-2 bg-white rounded shadow-md z-10 w-40 overflow-hidden">
                  <Link to="keywords" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Keywords
                    </button>
                  </Link>
                  <Link to="cta" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      CTA
                    </button>
                  </Link>
                  <Link to="features" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Key features
                    </button>
                  </Link>
                  <Link to="benefits" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Benefits
                    </button>
                  </Link>
                  <Link to="outcomes" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Emotional Outcomes
                    </button>
                  </Link>
                  <Link to="Brand" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Brand
                    </button>
                  </Link>
                  <Link to="Differentiators" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Differentiators
                    </button>
                  </Link>
                  <Link to="Pricingterms/offer" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Pricing terms/ offer
                    </button>
                  </Link>
                  <Link to="Competitiveadvantage" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Competitive advantage
                    </button>
                  </Link>
                  <Link to="Expertise" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Expertise
                    </button>
                  </Link>
                  <Link to="Solution-Orientedcopy" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Solution-Oriented copy
                    </button>
                  </Link>
                  <Link to="Urgency" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Urgency
                    </button>
                  </Link>
                  <Link
                    to="PersonalizedExperience"
                    smooth={true}
                    duration={500}
                  >
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Personalized Experience
                    </button>
                  </Link>
                  <Link to="Edgy/funny" smooth={true} duration={500}>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      Edgy/funny
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <Element name="keywords">
            <Table
              heading="Headlines and descriptions with keywords"
              data={tables.keywords}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="cta">
            <Table
              heading="Headlines and descriptions with Call to Actions"
              data={tables.cta}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="features">
            <Table
              heading=" Headlines and descriptions focusing on Key Features"
              data={tables.features}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="benefits">
            <Table
              heading="Headlines and descriptions focusing on Benefits"
              data={tables.benefits}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="outcomes">
            <Table
              heading=" Headlines and descriptions focusing on Emotional Outcomes"
              data={tables.feelings}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Brand">
            <Table
              heading="Headlines and descriptions focusing on the brand"
              data={tables.brand}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Differentiators">
            <Table
              heading=" Headlines and descriptions focusing on differentiators"
              data={tables.differentiators}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Pricingterms/offer">
            <Table
              heading="Headlines and descriptions focusing on pricing terms/ offer"
              data={tables.offer}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Competitiveadvantage">
            <Table
              heading={` Headlines and descriptions focusing on competitive advantage and conveying how ${formInfo.companyName} is the best`}
              data={tables.competitive}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Expertise">
            <Table
              heading="Headlines and descriptions focusing on credentials, experience,
          expertise, and authority of the company"
              data={tables.expertise}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Solution-Orientedcopy">
            <Table
              heading=" Headlines and descriptions focusing on Solution-Oriented copy"
              data={tables.solution}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Urgency">
            <Table
              heading=" Headlines and descriptions focusing on Urgency"
              data={tables.urgency}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="PersonalizedExperience">
            <Table
              heading="Headlines and descriptions focusing on Personalized Experience"
              data={tables.personalized}
              isRegenerating={isRegenerating}
              setIsRegenerating={setIsRegenerating}
            />
          </Element>
          <Element name="Edgy/funny">
            <div className="flex flex-col  justify-center text-slate-50">
              <h1 className="font-semibold text-2xl mb-5">
                Three edgy, funny, catchy, and off-the-wall headlines
              </h1>
              <ul className="flex flex-col gap-2">
                {tables.funny.map((object, index) => (
                  <li key={index} className="flex items-center">
                    <BiCopy
                      className="cursor-pointer !text-[1.3rem]"
                      onClick={() => {copyText(object)}}
                    />
                    <p
                      className="headline md:max-w-[600px]"
                      onClick={() => {
                        copyText(object);
                      }}
                    >
                      {" "}
                      {index + 1 + "."} {object}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </Element>
        </div>
      )}
    </div>
  );
};

export default Tables;
