import React, { useContext } from "react";
//functions
import { exportToExcel, exportToCSV } from "../../utils/export";
import { WebsiteContext } from "../../context/WebsiteContext";
const DropDown = (props) => {
  const ctx = useContext(WebsiteContext);
  const { isDropdownOpen, setIsDropdownOpen,     setIsSectionOpen } = ctx;

  const toggleMenu = () => {
    setIsSectionOpen(false);
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={toggleMenu}
        className="bg-slate-50 px-4 p-2 rounded-md shadow-md w-[90vw] xs:w-auto"
      >
        Export
      </button>
      {isDropdownOpen && (
        <div className="absolute mt-2 bg-white rounded shadow-md z-10 w-40 overflow-hidden">
          <button
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
            onClick={() => {
              toggleMenu();
              exportToExcel(props.data, "GoogleAds");
            }}
          >
            Excel
          </button>
          <button
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
            onClick={() => {
              toggleMenu();
              exportToCSV(props.data, "GoogleAds");
            }}
          >
            CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default DropDown;
