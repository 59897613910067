import React, { useState } from "react";

const SupportingInfoForm = (props) => {
  const { supporting } = props;
  //When charackters reach max number
  const [isMaxLengthReached, setIsMaxLengthReached] = useState(false);
  const maxLength = 2000;

  const handleChange = (event) => {
    supporting.onChange(event.target.value);
    const currentLength = event.target.value.length;
    setIsMaxLengthReached(currentLength >= maxLength);
  };

  const textareaStyle = {
    backgroundColor: isMaxLengthReached ? "#fe4848" : "#F1F1F1",
    color: isMaxLengthReached ? "white" : 'black'
  };

  return (
    <div className="flex flex-col mx-2 w-full">
      <label htmlFor="supporting" className="mb-2">
        Supporting info (max-{maxLength} characters):
      </label>
      <textarea
      placeholder="Type here"
        id="supporting"
        className={`border h-[445px] w-full resize-none overflow-y-auto px-4 py-2 `}
        maxLength={maxLength}
        onChange={handleChange}
        value={supporting.value}
        style={textareaStyle}
        onBlur={() => {
          supporting.inputBlurHandler();
        }}
      />
    </div>
  );
};

export default SupportingInfoForm;
