import { useState } from "react";

const useInput = (validateValue, defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const [isTouched, setIsTouched] = useState(false);
  const valueIsValid = validateValue(value);
  const [hasError, setHasError] = useState(!valueIsValid && isTouched);

  const urlIsValid = value.includes(" ") || value.includes(",");

  const handleChange = (event) => {
    setValue(event);
    setHasError(!validateValue(event));
  };
  const inputBlurHandler = () => {
    setIsTouched(true);
  };
  const reset = () => {
    setValue("");
    setIsTouched(false);
  };
  return {
    hasError,
    urlError: urlIsValid,
    value,
    onChange: handleChange,
    reset,
    inputBlurHandler,
    setHasError,
  };
};

export default useInput;
