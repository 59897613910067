export const calcProgress = (inputs) => {
  const numOfInput = Object.keys(inputs).length;
  let inputsEntered = 0;
  for(let input in inputs){
    if(inputs[input] !== ""){
        inputsEntered = inputsEntered + 1;
    }
  }
  return Math.round(inputsEntered / numOfInput * 100).toString()
};
