import React from "react";

const aboutCompany = (props) => {
  const { companyName, industry, service, offer, cta, keywords } = props;
  return (
    <div className="
    pb-[15px]
    ">
      <label htmlFor="companyName">Company name<sup>*</sup>: </label>
      <input
        placeholder="Type here (required)"
        type="text"
        id="companyName"
        className={`${companyName.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onBlur={() => {
          companyName.inputBlurHandler();
        }}
        onChange={(e) => {
          companyName.onChange(e.target.value);
        }}
        value={companyName.value}
      />

      <label htmlFor="industry">Industry that company is in<sup>*</sup>: </label>
      <input
        placeholder="Type here (required)"
        type="text"
        id="industry"
        className={`${industry.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onChange={(e) => {
          industry.onChange(e.target.value);
        }}
        onBlur={() => {
          industry.inputBlurHandler();
        }}
        value={industry.value}
      />

      <label htmlFor="product/serviceName">Service/product name<sup>*</sup>:</label>
      <input
      placeholder="Type here (required)"
        type="text"
        id="product/serviceName"
        className={`${service.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onChange={(e) => {
          service.onChange(e.target.value);
        }}
        onBlur={() => {
          service.inputBlurHandler();
        }}
        value={service.value}
      />

      <label htmlFor="offer">Offer<sup>*</sup>:</label>
      <input
        placeholder="Type here (required)"
        type="text"
        id="offer"
        className={`${offer.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onChange={(e) => {
          offer.onChange(e.target.value);
        }}
        onBlur={() => {
          offer.inputBlurHandler();
        }}
        value={offer.value}
      />

      <label htmlFor="cta">Call to action<sup>*</sup>:</label>
      <input
        placeholder="Type here (required)"
        type="text"
        id="cta" 
        className={`${cta.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onChange={(e) => {
          cta.onChange(e.target.value);
        }}
        onBlur={() => {
          cta.inputBlurHandler();
        }}
        value={cta.value}
      />

      <label htmlFor="keywords">Keywords<sup>*</sup>:</label>
      <input
        placeholder="Type here (required)"
        type="text"
        id="keywords"
        className={`${keywords.hasError && "bg-red-400 text-slate-50 placeholder:text-slate-50"} border w-full mb-5 px-2`}
        onChange={(e) => {
          keywords.onChange(e.target.value);
        }}
        onBlur={() => {
          keywords.inputBlurHandler();
        }}
        value={keywords.value}
      />
    </div>
  );
};

export default aboutCompany;
