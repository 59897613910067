import React from "react";

const CharCount = (props) => {
    const {text,maxNum} = props;
  if (typeof text === "string") {
    return (
      <p className="text-[0.8rem] text-gray-500">
        <span >
          {text.length}
        </span>{" "}
        / <span >{maxNum}</span> Characters
      </p>
    );
  }
  return <p>Error counting characters!.</p>;
};

export default CharCount;
