//conntection to the website need's to be https (secured)

const handleCopyText = async (textToCopy) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      //console.log("Copied");
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  } else {
    console.error("Clipboard API is not supported in this environment.");
  }
};

export default handleCopyText;
