import React, { useContext, useEffect } from "react";

//Styles
import "./App.css";
//Router

//Context
import { WebsiteContext } from "./context/WebsiteContext";

//Components
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "./components/UI/Modal";
import Form from "./components/UI/Forms/Form";
import Tables from "./components/Table/Tables";

function App() {
  //? Context
  const ctx = useContext(WebsiteContext);
  const { tables, setTables, setError, setLoading } = ctx;
  //? stoping background scroll behind the error modal
  useEffect(() => {
    if (ctx.error) {
      document.body.classList.add("no-scroll");
      const form = document.getElementById("form");
      if (form) {
        const formDistance = form.getBoundingClientRect().y;
        window.scrollTo(0, formDistance + 100);
      }
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [ctx.error]);

  //? Fetching data from ChatGPT
  const fetchData = async (info, data) => {
    ctx.setLoading(true);
    try {
      try {
        console.log("Running fetchData in app.js");
        const response = await fetch(
          "https://ads-generator.searchactions.com:3003/claude",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ info, data }),
          }
        );

        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }

        const result = await response.json();

        //? Claude

        if (result.includes("{")) {
          const formattedResult = result.slice(result.indexOf("{"));
          const jsonOutput = JSON.parse(formattedResult);
          console.log(jsonOutput);
          setTables(jsonOutput);
        } else {
          setError(result);
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className={`
   
    min-h-[100vh]  relative
    flex 
    items-start
    justify-center
    `}
    >
      <>
        {!tables && <Form handleSubmit={fetchData} />}

        {tables && (
          <>
            <div
              className="
                w-full
                flex
                items-center
                justify-center
                relative
                
                "
            >
              <div
                className="
                w-full
                h-[100vh]
                bg-gradient-to-b
                from-[#800f7d]
                via-[#29123f]
                to-[#090106]
                fixed
                top-0
                left-0
                "
              ></div>
              <Tables fetchData={fetchData} />
            </div>
          </>
        )}
      </>

      <ClipLoader
        color={"#ffff"}
        loading={ctx.loading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{
          position: "absolute",
          color: "#ffff",
          left: "50vw",
          top: "50vh",
          translate: "-50% -50%",
        }}
      />

      <Modal />
    </div>
  );
}

export default App;
