import React, { createContext, useState } from "react";

export const WebsiteContext = createContext();

const WebsiteProvider = (props) => {
  const [page, setPage] = useState(0);
  const [isScraping, setIsScraping] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formInfo, setFormInfo] = useState({
    related_1: "",
    related_2: "",
    related_3: "",
    homepage: "",
    serviceUrl: "",
    about: "",
    supporting: "",
    companyName: "",
    industry: "",
    service: "",
    offer: "",
    cta: "",
    keywords: "",
  });
  const [scrapedInfo, setScrapeInfo] = useState();
  const [tables, setTables] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSectionOpen , setIsSectionOpen ] = useState(false);
  const websiteContext = {
    formInfo,
    setFormInfo,
    error,
    setError,
    loading,
    setLoading,
    scrapedInfo,
    setScrapeInfo,
    tables,
    setTables,
    page,
    setPage,
    isScraping,
    setIsScraping,
    isDropdownOpen,
    setIsDropdownOpen,
    isSectionOpen,
    setIsSectionOpen
  };

  return (
    <WebsiteContext.Provider value={websiteContext}>
      {props.children}
    </WebsiteContext.Provider>
  );
};

export default WebsiteProvider;
